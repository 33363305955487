import React from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import "@fortawesome/fontawesome-svg-core/styles.css";
import SubHero from "./components/SubHero";
import Services from "./components/Services";
import DotContainerTop from "./components/DotContainers";
import DotContainerBottom from "./components/DotContainers/bottom";
import ProcessCard from "./components/ProcessCard";
import Footer from "./components/Footer";
import Form from "./components/Form";
import { BrowserRouter } from "react-router-dom";


function App() {
  return (
    <BrowserRouter>
    <div>
      <Navbar />
      <div style={{ width: "80%", margin: "auto" }}>
        <section>
          <Hero />
        </section>
        <section id="about"></section>
        <section >
          <SubHero />
        </section>
        <section id="services">
          <Services />
        </section>
        <section>
          <Form />
        </section>
        <section>
          <DotContainerTop />
        </section>
        <section id="process"></section>
        <section>
          <ProcessCard />
        </section>
        <section>
          <DotContainerBottom />
        </section>
        <Footer />
      </div>
    </div>
    </BrowserRouter>
  );
}

export default App;
