import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { navLinks } from "../../constants/navLinks";
import "./btnStyle.css";
import navIcon from "../../assets/cards/navcom.svg";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.matchMedia('(max-width: 767px)').matches;
      setIsMobile(isMobileView);
    };

    handleResize(); // Call the function on initial load

    window.addEventListener('resize', handleResize); // Add event listener for window resize

    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup the event listener on component unmount
    };
  }, []);

  const scrollToSection = () => {
    const section = document.getElementById('formId'); // Replace 'sectionId' with the ID of the section you want to scroll to
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' }); // Scrolls to the section with smooth animation
    }
  };
  
  return (
<nav className={`border-gray-200 fixed w-full top-0 z-10 ${isMobile ? 'bg-[#111111]' : isScrolled ? 'bg-[#111111]' : 'md:bg-transparent'}`}>
  <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 ">
    <div className="font-poppins font-bold text-[23px] text-white flex items-center">
    <a href="#">
      Arkie Digital<span className="text-[#A9F868]">.</span>
      </a>
    </div>
    <button
    
      data-collapse-toggle="navbar-dropdown"
      type="button"
      className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      aria-controls="navbar-dropdown"
      aria-expanded={isMenuOpen ? "true" : "false"}
      onClick={handleMenuToggle}
    >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div
          className={`${
            isMenuOpen ? "block" : "hidden"
          } w-full md:block md:w-auto`}
          id="navbar-dropdown"
        >
          <ul className="font-medium flex flex-col rounded-lg md:flex-row md:space-x-8 p-4 md:p-0 mt-4 md:mt-0  ">
            {navLinks.map((link) => (
              <li key={link.id}>
                <a
                  href={`#${link.id}`}
                  className="block py-2 pl-3 pr-5 pt-4 mt-1 rounded  md:border-0 md:hover:text-[#A9F868] md:p-0 md:dark:hover:text-[#A9F868]  dark:hover:text-[#A9F868]"
                  aria-current={link.id === "home" ? "page" : undefined}
                >
                  {link.title}
                </a>
              </li>
            ))}

            <li className="">
              <button
              onClick={scrollToSection}
                className={`mt-4 md:mt-0 navBtn rounded-full flex border-[#A9F868] py-[4px] px-[16px] hover:bg-[#A9F868] hover:text-[#111111] ${
                  isMenuOpen ? "bg-[#111111]" : ""
                }`}
                style={{ borderWidth: "2px" }}
              >
                Let's Talk{" "}
                <span>
                  <div>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.09 21.4999C11.0106 21.5143 10.9293 21.5143 10.85 21.4999C10.6768 21.4476 10.5254 21.3402 10.4189 21.194C10.3124 21.0478 10.2566 20.8708 10.26 20.6899V20.5799L11.16 14.2299H6.81995C6.6736 14.23 6.53001 14.19 6.40482 14.1142C6.27963 14.0384 6.17764 13.9297 6.10995 13.7999C6.03348 13.6695 5.99316 13.5211 5.99316 13.3699C5.99316 13.2187 6.03348 13.0703 6.10995 12.9399L8.10995 9.4499L12.21 2.9299C12.3011 2.77607 12.4417 2.65768 12.6088 2.59411C12.7759 2.53053 12.9596 2.52553 13.13 2.5799C13.3031 2.63218 13.4545 2.7396 13.561 2.88582C13.6675 3.03203 13.7233 3.20904 13.72 3.3899V3.4999L12.82 9.84991H17.17C17.3163 9.84978 17.4599 9.8898 17.5851 9.96562C17.7103 10.0414 17.8123 10.1501 17.88 10.2799C17.9564 10.4103 17.9967 10.5587 17.9967 10.7099C17.9967 10.8611 17.9564 11.0095 17.88 11.1399L15.88 14.6299L11.78 21.1499C11.7046 21.2625 11.6016 21.3539 11.4807 21.4152C11.3599 21.4764 11.2253 21.5056 11.09 21.4999ZM7.92995 12.6899H11.93C12.0403 12.6912 12.1492 12.7142 12.2507 12.7577C12.3521 12.8011 12.4439 12.8641 12.521 12.943C12.5981 13.0219 12.6589 13.1153 12.6999 13.2177C12.7409 13.3201 12.7613 13.4296 12.76 13.5399V13.6499L12.17 17.7899L14.67 13.7899L16.11 11.3099H12.11C11.9996 11.3086 11.8907 11.2856 11.7892 11.2422C11.6878 11.1987 11.596 11.1358 11.5189 11.0568C11.4418 10.9779 11.381 10.8845 11.3401 10.7821C11.2991 10.6797 11.2786 10.5702 11.28 10.4599V10.3499L11.87 6.2099L9.36995 10.2099L7.92995 12.6899Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

{
  /*


*/
}
