import React from "react";
import Button from "../Button";

const Form = () => {
  return (
    <div>
      <section id="formId">
        <div class="w-full p-8 my-4 md:px-12  lg:pl-40 lg:pr-40 mr-auto rounded-2xl shadow-2xl bg-[#111111]">
        <div className="text-3xl sm:text-4xl font-bold mt-[100px] sm:mt-[150px] mb-10 text-center justify-center">Drop us a line</div>

          <form
            action="https://formsubmit.co/9a475a0d2af840e656106575e5a2b6d5"
            method="POST"
          >
            <div class="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5 ">
              <input
                class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="First Name*"
                name="First Name"
              />
              <input
                class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="Last Name*"
                name="Last Name"
              />
              <input
                class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                type="email"
                placeholder="Email*"
                name="Email"
              />
              <input
                class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                type="number"
                placeholder="Phone*"
                name="Phone"
              />
            </div>
            <div class="my-4">
              <textarea
                placeholder="Message*"
                name="Message"
                class="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              ></textarea>
            </div>
            <div class="my-2">
              <div className="flex justify-center mt-5">
                <Button text="Send Message" />
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Form;
