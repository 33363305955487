export const navLinks = [
    {
        id:'', 
        title: 'Home'
    },
    {
        id: 'about', 
        title: 'About Us'
    },
    {
        id: 'services', 
        title: 'Services'
    },
    {
        id: 'process', 
        title: 'Process'
    }
]